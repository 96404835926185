
.slider{
    /* right: 0; */
    /* opacity: 1; */
    background-color: #f7f9fa;
    position: fixed;
    transition:  1s ease-in-out;
    width: 50%;
    top: 0;
    min-height: 100vh;
    background-color: #fff;
    font-family: sans-serif;
    z-index: 35;

    right: -22cm;
    animation: 2s slow alternate running;
}

@keyframes slow {
    from{
        right: -22cm; 
    }
    to{
        right: 0;
    }
}

.slider-top {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
}

.cls_btn {
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-radius: 50%;
    font-size: 1rem;
    color: #616161;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.slider .slider-download {
    background-color: #fff;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1.2rem;
    font-weight: 600;
}

.detail {
    color: #000000b3;
}


button {
    padding: 0.7rem 1.2rem;
    background: #5268ff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}


.iod{

        padding: 0.7rem 1.2rem;
        background: #5268ff;
        color: #fff;
        border: none;
        cursor: pointer;
        border-radius: 5px;
}

.slider-list {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.card {
    background-color: #fff;
    border: 1px solid #e8ebed;
    margin-bottom: 0.5rem;
    cursor: pointer;
    border-radius: 3px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: .9rem;
    font-weight: 600;
}

.ssvvgg{
    fill: #5268ff;
    height: 1.5rem;
    color: #5268ff;
}

.tle {
    font-size: 1.2rem;
    font-weight: 600;
}

#q_close_{
    margin-top: 13px;
}